<template>
  <div class="home">
    <div class="banner main" ref="banner" @mouseenter="enterView" @mouseleave="leaveView">
      <div class="background-img">
        <img ref="frame" :src="frameUrl">
        <div class="view-player csp" ref="play" @click.stop="viewPlay">
          <div class="player-triangle"></div>
        </div>
        <div class="view-pause" ref="pause" @click.stop="viewPause"></div>
      </div>
      <div class="banner-info">
        <h3 class="banner-title">观天者</h3>
        <h4 class="banner-subtitle">以你的视角看天气</h4>
        <div class="banner-desc">
          <p class="banner-weather">{{currentView && currentView.weather[currentFrame]}}</p>
          <p class="banner-location flex-align">
            <img src="@/assets/images/home/Subtract.svg" alt="subtract" class="loaction-avg">
            {{currentView && currentView.location}}
          </p>
          <p class="banner-date">
            {{currentView &&  dayjs(currentView.timestamps[currentFrame]*1000).utc().local().format('YYYY-MM-DD THH:mm:ssZ')}}
          </p>
        </div>
      </div>
      <div class="banner-list-wrap">
        <ul class="banner-list flex-layout">
          <li class="banner-list-item csp" :class="{'currentView':currentViewIndex === index}"
            v-for="(item,index) in viewPortsData" :key="index" @click="switchView(index)"
            :style="{ backgroundImage: 'url(' + viewPortsData[index].urls[0] + ')' }">
            {{`0${index+1}`}}</li>
        </ul>
        <div class="controller flex-align">
          <div class="el-icon-arrow-left" @click="switchFrames('left')"></div>
          <div class="el-icon-arrow-right" @click="switchFrames('right')"></div>
          <progressBar @updateFrame="updateCurrentFrame" :progress="progress"></progressBar>
        </div>
      </div>
    </div>
    <div class="deploy main">
      <h3 class="deploy-title">观天派累计部署共 {{deployData['devices-volume']}} 台</h3>
      <div class="deploy-data">
        <div class="data">
          <p class="data-num">1</p>
          <p class="data-title">部署覆盖国家</p>
        </div>
        <div class="data">
          <p class="data-num">{{deployData['city-coverage']}}</p>
          <p class="data-title">部署覆盖城市</p>
        </div>
      </div>
      <globe :pointList="distributionList"></globe>
    </div>
    <div class="about-us main">
      <div class="left-about-info">
        <h3 class="about-title">关于我们</h3>
        <p class="about-discribe">
          {{aboutDiscritbe}}
        </p>
        <p class="about-discribe contact">团队目前处于创业初期，正在探索和打磨首款产品。若您想要咨询或者寻求商业合作，欢迎联系邮箱：<a class="email csp"
            href="javascript:;">contact@skyviewor.com</a>。</p>
      </div>
      <div class="right-about-detail">
        <button class="about-button csp">查看详情</button>
      </div>
    </div>

   
  </div>
</template>
<script>
  import progressBar from "./components/progress.vue"
  import globe from "./components/globe.vue"
  import login from "../login/login.vue"
  export default {
    name: "home",
    data() {
      return {
        deployData: {}, // 部署统计数据
        deployDistribution: {}, // 部署具体位置
        distributionList: [], // 设备点数据
        viewPortsData: [], // 视角数据
        currentView: null, // 当前视角
        currentViewIndex: 0, // 当前视角index
        currentFrame: undefined, // 当前帧
        totalFrames: 0, // 总帧数
        frameUrl: "", // 帧地址
        isPlaying: false, // 序列帧播放状态 true 播放中 false 暂停
        progress: 100, // 进度条进度 %
        aboutDiscritbe: '观天者（北京）科技有限公司，是一家基于 AI 视觉技术提供气象、环境等综合态势感知服务的初创科技企业。观天者致力于打造 AI 时代新型普惠的天气和环境观测网络体系、探索全民参与的新型气象商业模式、挖掘天气美学价值。',
       
      }
    },
    components: {
      progressBar,
      globe,
      login
    },
    watch: {
      // 监听视频播放状态
      // 控制播放/暂停按钮
      isPlaying(newVal, oldVal) {
        this.debounce(this.switchPlayingBtn(newVal))
      }
    },
    methods: {
      // 获取部署统计数据
      async getStatisticsData() {
        let resData = await Api.Home.getStatisticsData()
        this.deployData = resData.data
        // console.log('统计数据', resData)
      },
      // 获取部署具体位置
      async getCityDistribution() {
        let resData = await Api.Home.getcityDistribution()
        this.deployDistribution = resData.data
        // console.log('设备数据', resData)
        resData.data.distribution.forEach(element => {
          this.distributionList.push({
            name: element.city,
            lng: element.longitude,
            lat: element.latitude,
            data: element
          })
        });
      },
      // 获取全部视角数据
      async getViewports() {
        let params = {
          count: 4
        }
        let resData = await Api.Home.getViewports(params)
        // console.log('视角数据', resData)
        this.viewPortsData = resData.data
        this.switchView(0)
        this.preloadImg(resData.data);
      },
      // 设置进度条
      setProgress(progress) {
        this.$nextTick(() => {
          this.progress = progress
        })
      },
      // 更新当前帧
      updateCurrentFrame(progress) {
        this.setProgress(progress);
        this.currentFrame = Math.floor((progress / 100) * (this.totalFrames - 1));
        this.frameUrl = this.currentView.urls[this.currentFrame]
      },
      // 根据视频播放状态 改变按钮状态
      switchPlayingBtn(state) {
        this.$refs.play.style.display = state ? 'none' : "block"
        this.$refs.pause.style.display = state ? 'block' : "none"
      },
      // 连续点击防抖
      debounce(func) {
        let debounceTimer;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(func, 200);
      },
      // 鼠标进入主视角 
      enterView() {
        this.debounce(this.switchPlayingBtn(this.isPlaying))
      },
      // 鼠标离开主视角
      leaveView() {
        this.$refs.pause.style.display = "none"
        this.$refs.play.style.display = "none"
      },
      // 切换当前主视角
      // @param {Number} index 视角下标
      switchView(index) {
        this.isPlaying = false;
        this.currentView = this.viewPortsData[index];
        this.currentViewIndex = index;
        this.totalFrames = this.currentView.urls.length;
        this.currentFrame = this.totalFrames - 1;
        this.frameUrl = this.currentView.urls[this.currentFrame]
        this.setProgress(100);
      },
      // 开始播放
      viewPlay() {
        this.startViewport(this.currentFrame);
      },
      // 暂停播放
      viewPause() {
        this.startViewport(this.currentFrame, true);
      },
      // 切换帧   
      // @direction {String} left 向左切换 right 向右切换
      switchFrames(direction) {
        let i = this.currentFrame
        const frameList = this.currentView.urls;

        // 播放中切换帧，序列帧暂停
        if (this.isPlaying) {
          this.isPlaying = false;
        }
        // 向左切换帧
        if (direction == 'left') {
          if (i === 0) {
            return;
          }
          this.currentFrame--
        }
        // 向右切换帧
        if (direction == 'right') {
          if (i === this.totalFrames - 1) {
            return;
          }
          this.currentFrame++
        }
        // 更新背景图片
        this.frameUrl = frameList[this.currentFrame];
        // 更新进度条
        let newProgress = this.currentFrame / (this.totalFrames - 1) * 100
        this.progress = Math.max(0, Math.min(100, newProgress));
      },

      // 序列帧方法
      // @param {Number} startNum 开始播放的帧数
      // @param {Boolean} pause 是否暂停
      startViewport(startNum, pause) {
        const frameList = this.currentView.urls; // 帧列表
        const delay = 80; // 帧间隔
        this.isPlaying = !pause // 播放状态  true 播放 false 暂停  //播放状态与暂停状态相反
        const animation = (i) => {
          // 结束
          if (i === this.totalFrames) {
            i = 0
            this.setProgress(0);
            this.frameUrl = frameList[0]
            this.currentFrame = 0
          }
          // 暂停
          if (!this.isPlaying) {
            return;
          }
          // 播放
          this.frameUrl = frameList[i];
          this.currentFrame = i;
          setTimeout(() => {
            if (!this.isPlaying) {
              return;
            }
            animation(i + 1);
            let calcProgress = (i + 1) / (this.totalFrames - 1) * 100;
            this.progress = Math.max(0, Math.min(100, calcProgress));
          }, delay);
          // };
        }
        if (startNum) {
          animation(startNum);
          return;
        }
      },
      // 预加载图片
      preloadImg(data) {
        let images = new Array();
        data.map((el) => {
          el.urls.map((item, i) => {
            images[i] = new Image();
            images[i].src = item;
          })
        })
      },

    },
    mounted() {
      this.getStatisticsData();
      this.getCityDistribution();
      this.getViewports();
    },

  };
</script>

<style lang="less" scoped>
  @import "./less/home.less";
</style>